{
  "firstNight": [
    "DUSK",
    "Philosopher",
    "Kazali",
    "Alchemist",
    "Poppy Grower",
    "Yaggababble",
    "Magician",
    "MINION",
    "Snitch",
    "Lunatic",
    "Summoner",
    "DEMON",
    "King",
    "Sailor",
    "Marionette",
    "Engineer",
    "Preacher",
    "Lil' Monsta",
    "Lleech",
    "Poisoner",
    "Widow",
    "Courtier",
    "Snake Charmer",
    "Godfather",
    "Devil's Advocate",
    "Evil Twin",
    "Witch",
    "Cerenovus",
    "Fearmonger",
    "Harpy",
    "Mezepheles",
    "Pukka",
    "Pixie",
    "Huntsman",
    "Damsel",
    "Amnesiac",
    "Washerwoman",
    "Librarian",
    "Investigator",
    "Chef",
    "Empath",
    "Fortune Teller",
    "Butler",
    "Grandmother",
    "Clockmaker",
    "Dreamer",
    "Seamstress",
    "Steward",
    "Knight",
    "Noble",
    "Balloonist",
    "Shugenja",
    "Village Idiot",
    "Bounty Hunter",
    "Nightwatchman",
    "Cult Leader",
    "Spy",
    "Ogre",
    "High Priestess",
    "General",
    "Chambermaid",
    "Mathematician",
    "DAWN",
    "Leviathan",
    "Vizier"
  ],
  "otherNight": [
    "DUSK",
    "Philosopher",
    "Poppy Grower",
    "Sailor",
    "Engineer",
    "Preacher",
    "Poisoner",
    "Courtier",
    "Innkeeper",
    "Gambler",
    "Snake Charmer",
    "Monk",
    "Devil's Advocate",
    "Witch",
    "Cerenovus",
    "Pit-Hag",
    "Fearmonger",
    "Harpy",
    "Mezepheles",
    "Scarlet Woman",
    "Summoner",
    "Lunatic",
    "Exorcist",
    "Lycanthrope",
    "Legion",
    "Imp",
    "Zombuul",
    "Pukka",
    "Shabaloth",
    "Po",
    "Fang Gu",
    "No Dashii",
    "Vortox",
    "Vigormortis",
    "Ojo",
    "Al-Hadikhia",
    "Lleech",
    "Lil' Monsta",
    "Yaggababble",
    "Kazali",
    "Assassin",
    "Godfather",
    "Gossip",
    "Acrobat",
    "Hatter",
    "Barber",
    "Sweetheart",
    "Sage",
    "Banshee",
    "Professor",
    "Choirboy",
    "Huntsman",
    "Damsel",
    "Amnesiac",
    "Farmer",
    "Tinker",
    "Moonchild",
    "Grandmother",
    "Ravenkeeper",
    "Empath",
    "Fortune Teller",
    "Undertaker",
    "Dreamer",
    "Flowergirl",
    "Town Crier",
    "Oracle",
    "Seamstress",
    "Juggler",
    "Balloonist",
    "Village Idiot",
    "King",
    "Bounty Hunter",
    "Nightwatchman",
    "Cult Leader",
    "Butler",
    "Spy",
    "High Priestess",
    "General",
    "Chambermaid",
    "Mathematician",
    "DAWN",
    "Leviathan"
  ]
}
