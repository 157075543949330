[
  {
    "id": "Steward",
    "name": "Steward",
    "roleType": "townsfolk",
    "print": "./images/print/4 - Experimental/townsfolk/Steward_print.webp",
    "icon": "./images/icon/4 - Experimental/townsfolk/Steward_icon.webp",
    "version": "4b - Unreleased Experimental",
    "isDisabled": false
  },
  {
    "id": "Knight",
    "name": "Knight",
    "roleType": "townsfolk",
    "print": "./images/print/4 - Experimental/townsfolk/Knight_print.webp",
    "icon": "./images/icon/4 - Experimental/townsfolk/Knight_icon.webp",
    "version": "4b - Unreleased Experimental",
    "isDisabled": false
  },
  {
    "id": "Noble",
    "name": "Noble",
    "roleType": "townsfolk",
    "print": "./images/print/4 - Experimental/townsfolk/Noble_print.webp",
    "icon": "./images/icon/4 - Experimental/townsfolk/Noble_icon.webp",
    "version": "4a - Kickstarter Experimental",
    "isDisabled": false
  },
  {
    "id": "Chef",
    "name": "Chef",
    "roleType": "townsfolk",
    "print": "./images/print/1 - Trouble Brewing/townsfolk/Chef_print.webp",
    "icon": "./images/icon/1 - Trouble Brewing/townsfolk/Chef_icon.webp",
    "version": "1 - Trouble Brewing",
    "isDisabled": false
  },
  {
    "id": "Investigator",
    "name": "Investigator",
    "roleType": "townsfolk",
    "print": "./images/print/1 - Trouble Brewing/townsfolk/Investigator_print.webp",
    "icon": "./images/icon/1 - Trouble Brewing/townsfolk/Investigator_icon.webp",
    "version": "1 - Trouble Brewing",
    "isDisabled": false
  },
  {
    "id": "Washerwoman",
    "name": "Washerwoman",
    "roleType": "townsfolk",
    "print": "./images/print/1 - Trouble Brewing/townsfolk/Washerwoman_print.webp",
    "icon": "./images/icon/1 - Trouble Brewing/townsfolk/Washerwoman_icon.webp",
    "version": "1 - Trouble Brewing",
    "isDisabled": false
  },
  {
    "id": "Clockmaker",
    "name": "Clockmaker",
    "roleType": "townsfolk",
    "print": "./images/print/3 - Sects and Violets/townsfolk/Clockmaker_print.webp",
    "icon": "./images/icon/3 - Sects and Violets/townsfolk/Clockmaker_icon.webp",
    "version": "3 - Sects and Violets",
    "isDisabled": false
  },
  {
    "id": "Librarian",
    "name": "Librarian",
    "roleType": "townsfolk",
    "print": "./images/print/1 - Trouble Brewing/townsfolk/Librarian_print.webp",
    "icon": "./images/icon/1 - Trouble Brewing/townsfolk/Librarian_icon.webp",
    "version": "1 - Trouble Brewing",
    "isDisabled": false
  },
  {
    "id": "Grandmother",
    "name": "Grandmother",
    "roleType": "townsfolk",
    "print": "./images/print/2 - Bad Moon Rising/townsfolk/Grandmother_print.webp",
    "icon": "./images/icon/2 - Bad Moon Rising/townsfolk/Grandmother_icon.webp",
    "version": "2 - Bad Moon Rising",
    "isDisabled": false
  },
  {
    "id": "Bounty Hunter",
    "name": "Bounty Hunter",
    "roleType": "townsfolk",
    "print": "./images/print/4 - Experimental/townsfolk/Bounty Hunter_print.webp",
    "icon": "./images/icon/4 - Experimental/townsfolk/Bounty Hunter_icon.webp",
    "version": "4b - Unreleased Experimental",
    "isDisabled": false
  },
  {
    "id": "Shugenja",
    "name": "Shugenja",
    "roleType": "townsfolk",
    "print": "./images/print/4 - Experimental/townsfolk/Shugenja_print.webp",
    "icon": "./images/icon/4 - Experimental/townsfolk/Shugenja_icon.webp",
    "version": "4b - Unreleased Experimental",
    "isDisabled": false
  },
  {
    "id": "Pixie",
    "name": "Pixie",
    "roleType": "townsfolk",
    "print": "./images/print/4 - Experimental/townsfolk/Pixie_print.webp",
    "icon": "./images/icon/4 - Experimental/townsfolk/Pixie_icon.webp",
    "version": "4a - Kickstarter Experimental",
    "isDisabled": false
  },
  {
    "id": "Empath",
    "name": "Empath",
    "roleType": "townsfolk",
    "print": "./images/print/1 - Trouble Brewing/townsfolk/Empath_print.webp",
    "icon": "./images/icon/1 - Trouble Brewing/townsfolk/Empath_icon.webp",
    "version": "1 - Trouble Brewing",
    "isDisabled": false
  },
  {
    "id": "High Priestess",
    "name": "High Priestess",
    "roleType": "townsfolk",
    "print": "./images/print/4 - Experimental/townsfolk/High Priestess_print.webp",
    "icon": "./images/icon/4 - Experimental/townsfolk/High Priestess_icon.webp",
    "version": "4b - Unreleased Experimental",
    "isDisabled": false
  },
  {
    "id": "Sailor",
    "name": "Sailor",
    "roleType": "townsfolk",
    "print": "./images/print/2 - Bad Moon Rising/townsfolk/Sailor_print.webp",
    "icon": "./images/icon/2 - Bad Moon Rising/townsfolk/Sailor_icon.webp",
    "version": "2 - Bad Moon Rising",
    "isDisabled": false
  },
  {
    "id": "General",
    "name": "General",
    "roleType": "townsfolk",
    "print": "./images/print/4 - Experimental/townsfolk/General_print.webp",
    "icon": "./images/icon/4 - Experimental/townsfolk/General_icon.webp",
    "version": "4a - Kickstarter Experimental",
    "isDisabled": false
  },
  {
    "id": "Preacher",
    "name": "Preacher",
    "roleType": "townsfolk",
    "print": "./images/print/4 - Experimental/townsfolk/Preacher_print.webp",
    "icon": "./images/icon/4 - Experimental/townsfolk/Preacher_icon.webp",
    "version": "4b - Unreleased Experimental",
    "isDisabled": false
  },
  {
    "id": "Chambermaid",
    "name": "Chambermaid",
    "roleType": "townsfolk",
    "print": "./images/print/2 - Bad Moon Rising/townsfolk/Chambermaid_print.webp",
    "icon": "./images/icon/2 - Bad Moon Rising/townsfolk/Chambermaid_icon.webp",
    "version": "2 - Bad Moon Rising",
    "isDisabled": false
  },
  {
    "id": "Dreamer",
    "name": "Dreamer",
    "roleType": "townsfolk",
    "print": "./images/print/3 - Sects and Violets/townsfolk/Dreamer_print.webp",
    "icon": "./images/icon/3 - Sects and Violets/townsfolk/Dreamer_icon.webp",
    "version": "3 - Sects and Violets",
    "isDisabled": false
  },
  {
    "id": "Village Idiot",
    "name": "Village Idiot",
    "roleType": "townsfolk",
    "print": "./images/print/4 - Experimental/townsfolk/Village Idiot_Print.webp",
    "icon": "./images/icon/4 - Experimental/townsfolk/Village Idiot_icon.webp",
    "version": "4b - Unreleased Experimental",
    "isDisabled": false
  },
  {
    "id": "Snake Charmer",
    "name": "Snake Charmer",
    "roleType": "townsfolk",
    "print": "./images/print/3 - Sects and Violets/townsfolk/Snake Charmer_print.webp",
    "icon": "./images/icon/3 - Sects and Violets/townsfolk/Snake Charmer_icon.webp",
    "version": "3 - Sects and Violets",
    "isDisabled": false
  },
  {
    "id": "Balloonist",
    "name": "Balloonist",
    "roleType": "townsfolk",
    "print": "./images/print/4 - Experimental/townsfolk/Balloonist_print.webp",
    "icon": "./images/icon/4 - Experimental/townsfolk/Balloonist_icon.webp",
    "version": "4b - Unreleased Experimental",
    "isDisabled": false
  },
  {
    "id": "Mathematician",
    "name": "Mathematician",
    "roleType": "townsfolk",
    "print": "./images/print/3 - Sects and Violets/townsfolk/Mathematician_print.webp",
    "icon": "./images/icon/3 - Sects and Violets/townsfolk/Mathematician_icon.webp",
    "version": "3 - Sects and Violets",
    "isDisabled": false
  },
  {
    "id": "Cult Leader",
    "name": "Cult Leader",
    "roleType": "townsfolk",
    "print": "./images/print/4 - Experimental/townsfolk/Cult Leader_print.webp",
    "icon": "./images/icon/4 - Experimental/townsfolk/Cult Leader_icon.webp",
    "version": "4b - Unreleased Experimental",
    "isDisabled": false
  },
  {
    "id": "Fortune Teller",
    "name": "Fortune Teller",
    "roleType": "townsfolk",
    "print": "./images/print/1 - Trouble Brewing/townsfolk/Fortune Teller_print.webp",
    "icon": "./images/icon/1 - Trouble Brewing/townsfolk/Fortune Teller_icon.webp",
    "version": "1 - Trouble Brewing",
    "isDisabled": false
  },
  {
    "id": "King",
    "name": "King",
    "roleType": "townsfolk",
    "print": "./images/print/4 - Experimental/townsfolk/King_print.webp",
    "icon": "./images/icon/4 - Experimental/townsfolk/King_icon.webp",
    "version": "4a - Kickstarter Experimental",
    "isDisabled": false
  },
  {
    "id": "Flowergirl",
    "name": "Flowergirl",
    "roleType": "townsfolk",
    "print": "./images/print/3 - Sects and Violets/townsfolk/Flowergirl_print.webp",
    "icon": "./images/icon/3 - Sects and Violets/townsfolk/Flowergirl_icon.webp",
    "version": "3 - Sects and Violets",
    "isDisabled": false
  },
  {
    "id": "Town Crier",
    "name": "Town Crier",
    "roleType": "townsfolk",
    "print": "./images/print/3 - Sects and Violets/townsfolk/Town Crier_print.webp",
    "icon": "./images/icon/3 - Sects and Violets/townsfolk/Town Crier_icon.webp",
    "version": "3 - Sects and Violets",
    "isDisabled": false
  },
  {
    "id": "Oracle",
    "name": "Oracle",
    "roleType": "townsfolk",
    "print": "./images/print/3 - Sects and Violets/townsfolk/Oracle_print.webp",
    "icon": "./images/icon/3 - Sects and Violets/townsfolk/Oracle_icon.webp",
    "version": "3 - Sects and Violets",
    "isDisabled": false
  },
  {
    "id": "Undertaker",
    "name": "Undertaker",
    "roleType": "townsfolk",
    "print": "./images/print/1 - Trouble Brewing/townsfolk/Undertaker_print.webp",
    "icon": "./images/icon/1 - Trouble Brewing/townsfolk/Undertaker_icon.webp",
    "version": "1 - Trouble Brewing",
    "isDisabled": false
  },
  {
    "id": "Innkeeper",
    "name": "Innkeeper",
    "roleType": "townsfolk",
    "print": "./images/print/2 - Bad Moon Rising/townsfolk/Innkeeper_print.webp",
    "icon": "./images/icon/2 - Bad Moon Rising/townsfolk/Innkeeper_icon.webp",
    "version": "2 - Bad Moon Rising",
    "isDisabled": false
  },
  {
    "id": "Gambler",
    "name": "Gambler",
    "roleType": "townsfolk",
    "print": "./images/print/2 - Bad Moon Rising/townsfolk/Gambler_print.webp",
    "icon": "./images/icon/2 - Bad Moon Rising/townsfolk/Gambler_icon.webp",
    "version": "2 - Bad Moon Rising",
    "isDisabled": false
  },
  {
    "id": "Monk",
    "name": "Monk",
    "roleType": "townsfolk",
    "print": "./images/print/1 - Trouble Brewing/townsfolk/Monk_print.webp",
    "icon": "./images/icon/1 - Trouble Brewing/townsfolk/Monk_icon.webp",
    "version": "1 - Trouble Brewing",
    "isDisabled": false
  },
  {
    "id": "Lycanthrope",
    "name": "Lycanthrope",
    "roleType": "townsfolk",
    "print": "./images/print/4 - Experimental/townsfolk/Lycanthrope_print.webp",
    "icon": "./images/icon/4 - Experimental/townsfolk/Lycanthrope_icon.webp",
    "version": "4a - Kickstarter Experimental",
    "isDisabled": false
  },
  {
    "id": "Exorcist",
    "name": "Exorcist",
    "roleType": "townsfolk",
    "print": "./images/print/2 - Bad Moon Rising/townsfolk/Exorcist_print.webp",
    "icon": "./images/icon/2 - Bad Moon Rising/townsfolk/Exorcist_icon.webp",
    "version": "2 - Bad Moon Rising",
    "isDisabled": false
  },
  {
    "id": "Gossip",
    "name": "Gossip",
    "roleType": "townsfolk",
    "print": "./images/print/2 - Bad Moon Rising/townsfolk/Gossip_print.webp",
    "icon": "./images/icon/2 - Bad Moon Rising/townsfolk/Gossip_icon.webp",
    "version": "2 - Bad Moon Rising",
    "isDisabled": false
  },
  {
    "id": "Savant",
    "name": "Savant",
    "roleType": "townsfolk",
    "print": "./images/print/3 - Sects and Violets/townsfolk/Savant_print.webp",
    "icon": "./images/icon/3 - Sects and Violets/townsfolk/Savant_icon.webp",
    "version": "3 - Sects and Violets",
    "isDisabled": false
  },
  {
    "id": "Amnesiac",
    "name": "Amnesiac",
    "roleType": "townsfolk",
    "print": "./images/print/4 - Experimental/townsfolk/Amnesiac_print.webp",
    "icon": "./images/icon/4 - Experimental/townsfolk/Amnesiac_icon.webp",
    "version": "4a - Kickstarter Experimental",
    "isDisabled": false
  },
  {
    "id": "Juggler",
    "name": "Juggler",
    "roleType": "townsfolk",
    "print": "./images/print/3 - Sects and Violets/townsfolk/Juggler_print.webp",
    "icon": "./images/icon/3 - Sects and Violets/townsfolk/Juggler_icon.webp",
    "version": "3 - Sects and Violets",
    "isDisabled": false
  },
  {
    "id": "Alsaahir",
    "name": "Alsaahir",
    "roleType": "townsfolk",
    "print": "./images/print/4 - Experimental/townsfolk/Alsaahir_print.webp",
    "icon": "./images/icon/4 - Experimental/townsfolk/Alsaahir_icon.webp",
    "version": "4b - Unreleased Experimental",
    "isDisabled": false
  },
  {
    "id": "Nightwatchman",
    "name": "Nightwatchman",
    "roleType": "townsfolk",
    "print": "./images/print/4 - Experimental/townsfolk/Nightwatchman_print.webp",
    "icon": "./images/icon/4 - Experimental/townsfolk/Nightwatchman_icon.webp",
    "version": "4b - Unreleased Experimental",
    "isDisabled": false
  },
  {
    "id": "Engineer",
    "name": "Engineer",
    "roleType": "townsfolk",
    "print": "./images/print/4 - Experimental/townsfolk/Engineer_print.webp",
    "icon": "./images/icon/4 - Experimental/townsfolk/Engineer_icon.webp",
    "version": "4a - Kickstarter Experimental",
    "isDisabled": false
  },
  {
    "id": "Artist",
    "name": "Artist",
    "roleType": "townsfolk",
    "print": "./images/print/3 - Sects and Violets/townsfolk/Artist_print.webp",
    "icon": "./images/icon/3 - Sects and Violets/townsfolk/Artist_icon.webp",
    "version": "3 - Sects and Violets",
    "isDisabled": false
  },
  {
    "id": "Courtier",
    "name": "Courtier",
    "roleType": "townsfolk",
    "print": "./images/print/2 - Bad Moon Rising/townsfolk/Courtier_print.webp",
    "icon": "./images/icon/2 - Bad Moon Rising/townsfolk/Courtier_icon.webp",
    "version": "2 - Bad Moon Rising",
    "isDisabled": false
  },
  {
    "id": "Slayer",
    "name": "Slayer",
    "roleType": "townsfolk",
    "print": "./images/print/1 - Trouble Brewing/townsfolk/Slayer_print.webp",
    "icon": "./images/icon/1 - Trouble Brewing/townsfolk/Slayer_icon.webp",
    "version": "1 - Trouble Brewing",
    "isDisabled": false
  },
  {
    "id": "Fisherman",
    "name": "Fisherman",
    "roleType": "townsfolk",
    "print": "./images/print/4 - Experimental/townsfolk/Fisherman_print.webp",
    "icon": "./images/icon/4 - Experimental/townsfolk/Fisherman_icon.webp",
    "version": "4b - Unreleased Experimental",
    "isDisabled": false
  },
  {
    "id": "Professor",
    "name": "Professor",
    "roleType": "townsfolk",
    "print": "./images/print/2 - Bad Moon Rising/townsfolk/Professor_print.webp",
    "icon": "./images/icon/2 - Bad Moon Rising/townsfolk/Professor_icon.webp",
    "version": "2 - Bad Moon Rising",
    "isDisabled": false
  },
  {
    "id": "Seamstress",
    "name": "Seamstress",
    "roleType": "townsfolk",
    "print": "./images/print/3 - Sects and Violets/townsfolk/Seamstress_print.webp",
    "icon": "./images/icon/3 - Sects and Violets/townsfolk/Seamstress_icon.webp",
    "version": "3 - Sects and Violets",
    "isDisabled": false
  },
  {
    "id": "Philosopher",
    "name": "Philosopher",
    "roleType": "townsfolk",
    "print": "./images/print/3 - Sects and Violets/townsfolk/Philosopher_print.webp",
    "icon": "./images/icon/3 - Sects and Violets/townsfolk/Philosopher_icon.webp",
    "version": "3 - Sects and Violets",
    "isDisabled": false
  },
  {
    "id": "Huntsman",
    "name": "Huntsman",
    "roleType": "townsfolk",
    "print": "./images/print/4 - Experimental/townsfolk/Huntsman_print.webp",
    "icon": "./images/icon/4 - Experimental/townsfolk/Huntsman_icon.webp",
    "version": "4a - Kickstarter Experimental",
    "isDisabled": false
  },
  {
    "id": "Soldier",
    "name": "Soldier",
    "roleType": "townsfolk",
    "print": "./images/print/1 - Trouble Brewing/townsfolk/Soldier_print.webp",
    "icon": "./images/icon/1 - Trouble Brewing/townsfolk/Soldier_icon.webp",
    "version": "1 - Trouble Brewing",
    "isDisabled": false
  },
  {
    "id": "Fool",
    "name": "Fool",
    "roleType": "townsfolk",
    "print": "./images/print/2 - Bad Moon Rising/townsfolk/Fool_print.webp",
    "icon": "./images/icon/2 - Bad Moon Rising/townsfolk/Fool_icon.webp",
    "version": "2 - Bad Moon Rising",
    "isDisabled": false
  },
  {
    "id": "Alchemist",
    "name": "Alchemist",
    "roleType": "townsfolk",
    "print": "./images/print/4 - Experimental/townsfolk/Alchemist_print.webp",
    "icon": "./images/icon/4 - Experimental/townsfolk/Alchemist_icon.webp",
    "version": "4a - Kickstarter Experimental",
    "isDisabled": false
  },
  {
    "id": "Pacifist",
    "name": "Pacifist",
    "roleType": "townsfolk",
    "print": "./images/print/2 - Bad Moon Rising/townsfolk/Pacifist_print.webp",
    "icon": "./images/icon/2 - Bad Moon Rising/townsfolk/Pacifist_icon.webp",
    "version": "2 - Bad Moon Rising",
    "isDisabled": false
  },
  {
    "id": "Tea Lady",
    "name": "Tea Lady",
    "roleType": "townsfolk",
    "print": "./images/print/2 - Bad Moon Rising/townsfolk/Tea Lady_print.webp",
    "icon": "./images/icon/2 - Bad Moon Rising/townsfolk/Tea Lady_icon.webp",
    "version": "2 - Bad Moon Rising",
    "isDisabled": false
  },
  {
    "id": "Sage",
    "name": "Sage",
    "roleType": "townsfolk",
    "print": "./images/print/3 - Sects and Violets/townsfolk/Sage_print.webp",
    "icon": "./images/icon/3 - Sects and Violets/townsfolk/Sage_icon.webp",
    "version": "3 - Sects and Violets",
    "isDisabled": false
  },
  {
    "id": "Farmer",
    "name": "Farmer",
    "roleType": "townsfolk",
    "print": "./images/print/4 - Experimental/townsfolk/Farmer_print.webp",
    "icon": "./images/icon/4 - Experimental/townsfolk/Farmer_icon.webp",
    "version": "4a - Kickstarter Experimental",
    "isDisabled": false
  },
  {
    "id": "Magician",
    "name": "Magician",
    "roleType": "townsfolk",
    "print": "./images/print/4 - Experimental/townsfolk/Magician_print.webp",
    "icon": "./images/icon/4 - Experimental/townsfolk/Magician_icon.webp",
    "version": "4a - Kickstarter Experimental",
    "isDisabled": false
  },
  {
    "id": "Choirboy",
    "name": "Choirboy",
    "roleType": "townsfolk",
    "print": "./images/print/4 - Experimental/townsfolk/Choirboy_print.webp",
    "icon": "./images/icon/4 - Experimental/townsfolk/Choirboy_icon.webp",
    "version": "4a - Kickstarter Experimental",
    "isDisabled": false
  },
  {
    "id": "Ravenkeeper",
    "name": "Ravenkeeper",
    "roleType": "townsfolk",
    "print": "./images/print/1 - Trouble Brewing/townsfolk/Ravenkeeper_print.webp",
    "icon": "./images/icon/1 - Trouble Brewing/townsfolk/Ravenkeeper_icon.webp",
    "version": "1 - Trouble Brewing",
    "isDisabled": false
  },
  {
    "id": "Poppy Grower",
    "name": "Poppy Grower",
    "roleType": "townsfolk",
    "print": "./images/print/4 - Experimental/townsfolk/Poppy Grower_print.webp",
    "icon": "./images/icon/4 - Experimental/townsfolk/Poppy Grower_icon.webp",
    "version": "4a - Kickstarter Experimental",
    "isDisabled": false
  },
  {
    "id": "Virgin",
    "name": "Virgin",
    "roleType": "townsfolk",
    "print": "./images/print/1 - Trouble Brewing/townsfolk/Virgin_print.webp",
    "icon": "./images/icon/1 - Trouble Brewing/townsfolk/Virgin_icon.webp",
    "version": "1 - Trouble Brewing",
    "isDisabled": false
  },
  {
    "id": "Minstrel",
    "name": "Minstrel",
    "roleType": "townsfolk",
    "print": "./images/print/2 - Bad Moon Rising/townsfolk/Minstrel_print.webp",
    "icon": "./images/icon/2 - Bad Moon Rising/townsfolk/Minstrel_icon.webp",
    "version": "2 - Bad Moon Rising",
    "isDisabled": false
  },
  {
    "id": "Cannibal",
    "name": "Cannibal",
    "roleType": "townsfolk",
    "print": "./images/print/4 - Experimental/townsfolk/Cannibal_print.webp",
    "icon": "./images/icon/4 - Experimental/townsfolk/Cannibal_icon.webp",
    "version": "4a - Kickstarter Experimental",
    "isDisabled": false
  },
  {
    "id": "Atheist",
    "name": "Atheist",
    "roleType": "townsfolk",
    "print": "./images/print/4 - Experimental/townsfolk/Atheist_print.webp",
    "icon": "./images/icon/4 - Experimental/townsfolk/Atheist_icon.webp",
    "version": "4a - Kickstarter Experimental",
    "isDisabled": false
  },
  {
    "id": "Mayor",
    "name": "Mayor",
    "roleType": "townsfolk",
    "print": "./images/print/1 - Trouble Brewing/townsfolk/Mayor_print.webp",
    "icon": "./images/icon/1 - Trouble Brewing/townsfolk/Mayor_icon.webp",
    "version": "1 - Trouble Brewing",
    "isDisabled": false
  },
  {
    "id": "Banshee",
    "name": "Banshee",
    "roleType": "townsfolk",
    "print": "./images/print/4 - Experimental/townsfolk/Banshee_print.webp",
    "icon": "./images/icon/4 - Experimental/townsfolk/Banshee_icon.webp",
    "version": "4b - Unreleased Experimental",
    "isDisabled": false
  },
  {
    "id": "Snitch",
    "name": "Snitch",
    "roleType": "outsider",
    "print": "./images/print/4 - Experimental/outsider/Snitch_print.webp",
    "icon": "./images/icon/4 - Experimental/outsider/Snitch_icon.webp",
    "version": "4a - Kickstarter Experimental",
    "isDisabled": false
  },
  {
    "id": "Ogre",
    "name": "Ogre",
    "roleType": "outsider",
    "print": "./images/print/4 - Experimental/outsider/Ogre_print.webp",
    "icon": "./images/icon/4 - Experimental/outsider/Ogre_icon.webp",
    "version": "4b - Unreleased Experimental",
    "isDisabled": false
  },
  {
    "id": "Butler",
    "name": "Butler",
    "roleType": "outsider",
    "print": "./images/print/1 - Trouble Brewing/outsider/Butler_print.webp",
    "icon": "./images/icon/1 - Trouble Brewing/outsider/Butler_icon.webp",
    "version": "1 - Trouble Brewing",
    "isDisabled": false
  },
  {
    "id": "Goon",
    "name": "Goon",
    "roleType": "outsider",
    "print": "./images/print/2 - Bad Moon Rising/outsider/Goon_print.webp",
    "icon": "./images/icon/2 - Bad Moon Rising/outsider/Goon_icon.webp",
    "version": "2 - Bad Moon Rising",
    "isDisabled": false
  },
  {
    "id": "Acrobat",
    "name": "Acrobat",
    "roleType": "outsider",
    "print": "./images/print/4 - Experimental/outsider/Acrobat_print.webp",
    "icon": "./images/icon/4 - Experimental/outsider/Acrobat_icon.webp",
    "version": "4b - Unreleased Experimental",
    "isDisabled": false
  },
  {
    "id": "Puzzlemaster",
    "name": "Puzzlemaster",
    "roleType": "outsider",
    "print": "./images/print/4 - Experimental/outsider/Puzzlemaster_print.webp",
    "icon": "./images/icon/4 - Experimental/outsider/Puzzlemaster_icon.webp",
    "version": "4a - Kickstarter Experimental",
    "isDisabled": false
  },
  {
    "id": "Tinker",
    "name": "Tinker",
    "roleType": "outsider",
    "print": "./images/print/2 - Bad Moon Rising/outsider/Tinker_print.webp",
    "icon": "./images/icon/2 - Bad Moon Rising/outsider/Tinker_icon.webp",
    "version": "2 - Bad Moon Rising",
    "isDisabled": false
  },
  {
    "id": "Saint",
    "name": "Saint",
    "roleType": "outsider",
    "print": "./images/print/1 - Trouble Brewing/outsider/Saint_print.webp",
    "icon": "./images/icon/1 - Trouble Brewing/outsider/Saint_icon.webp",
    "version": "1 - Trouble Brewing",
    "isDisabled": false
  },
  {
    "id": "Sweetheart",
    "name": "Sweetheart",
    "roleType": "outsider",
    "print": "./images/print/3 - Sects and Violets/outsider/Sweetheart_print.webp",
    "icon": "./images/icon/3 - Sects and Violets/outsider/Sweetheart_icon.webp",
    "version": "3 - Sects and Violets",
    "isDisabled": false
  },
  {
    "id": "Plague Doctor",
    "name": "Plague Doctor",
    "roleType": "outsider",
    "print": "./images/print/4 - Experimental/outsider/Plague Doctor_print.webp",
    "icon": "./images/icon/4 - Experimental/outsider/Plague Doctor_icon.webp",
    "version": "4b - Unreleased Experimental",
    "isDisabled": false
  },
  {
    "id": "Recluse",
    "name": "Recluse",
    "roleType": "outsider",
    "print": "./images/print/1 - Trouble Brewing/outsider/Recluse_print.webp",
    "icon": "./images/icon/1 - Trouble Brewing/outsider/Recluse_icon.webp",
    "version": "1 - Trouble Brewing",
    "isDisabled": false
  },
  {
    "id": "Heretic",
    "name": "Heretic",
    "roleType": "outsider",
    "print": "./images/print/4 - Experimental/outsider/Heretic_print.webp",
    "icon": "./images/icon/4 - Experimental/outsider/Heretic_icon.webp",
    "version": "4a - Kickstarter Experimental",
    "isDisabled": false
  },
  {
    "id": "Mutant",
    "name": "Mutant",
    "roleType": "outsider",
    "print": "./images/print/3 - Sects and Violets/outsider/Mutant_print.webp",
    "icon": "./images/icon/3 - Sects and Violets/outsider/Mutant_icon.webp",
    "version": "3 - Sects and Violets",
    "isDisabled": false
  },
  {
    "id": "Damsel",
    "name": "Damsel",
    "roleType": "outsider",
    "print": "./images/print/4 - Experimental/outsider/Damsel_print.webp",
    "icon": "./images/icon/4 - Experimental/outsider/Damsel_icon.webp",
    "version": "4a - Kickstarter Experimental",
    "isDisabled": false
  },
  {
    "id": "Klutz",
    "name": "Klutz",
    "roleType": "outsider",
    "print": "./images/print/3 - Sects and Violets/outsider/Klutz_print.webp",
    "icon": "./images/icon/3 - Sects and Violets/outsider/Klutz_icon.webp",
    "version": "3 - Sects and Violets",
    "isDisabled": false
  },
  {
    "id": "Drunk",
    "name": "Drunk",
    "roleType": "outsider",
    "print": "./images/print/1 - Trouble Brewing/outsider/Drunk_print.webp",
    "icon": "./images/icon/1 - Trouble Brewing/outsider/Drunk_icon.webp",
    "version": "1 - Trouble Brewing",
    "isDisabled": false
  },
  {
    "id": "Golem",
    "name": "Golem",
    "roleType": "outsider",
    "print": "./images/print/4 - Experimental/outsider/Golem_print.webp",
    "icon": "./images/icon/4 - Experimental/outsider/Golem_icon.webp",
    "version": "4a - Kickstarter Experimental",
    "isDisabled": false
  },
  {
    "id": "Moonchild",
    "name": "Moonchild",
    "roleType": "outsider",
    "print": "./images/print/2 - Bad Moon Rising/outsider/Moonchild_print.webp",
    "icon": "./images/icon/2 - Bad Moon Rising/outsider/Moonchild_icon.webp",
    "version": "2 - Bad Moon Rising",
    "isDisabled": false
  },
  {
    "id": "Politician",
    "name": "Politician",
    "roleType": "outsider",
    "print": "./images/print/4 - Experimental/outsider/Politician_print.webp",
    "icon": "./images/icon/4 - Experimental/outsider/Politician_icon.webp",
    "version": "4b - Unreleased Experimental",
    "isDisabled": false
  },
  {
    "id": "Lunatic",
    "name": "Lunatic",
    "roleType": "outsider",
    "print": "./images/print/2 - Bad Moon Rising/outsider/Lunatic_print.webp",
    "icon": "./images/icon/2 - Bad Moon Rising/outsider/Lunatic_icon.webp",
    "version": "2 - Bad Moon Rising",
    "isDisabled": false
  },
  {
    "id": "Barber",
    "name": "Barber",
    "roleType": "outsider",
    "print": "./images/print/3 - Sects and Violets/outsider/Barber_print.webp",
    "icon": "./images/icon/3 - Sects and Violets/outsider/Barber_icon.webp",
    "version": "3 - Sects and Violets",
    "isDisabled": false
  },
  {
    "id": "Hatter",
    "name": "Hatter",
    "roleType": "outsider",
    "print": "./images/print/4 - Experimental/outsider/Hatter_print.webp",
    "icon": "./images/icon/4 - Experimental/outsider/Hatter_icon.webp",
    "version": "4b - Unreleased Experimental",
    "isDisabled": false
  },
  {
    "id": "Widow",
    "name": "Widow",
    "roleType": "minion",
    "print": "./images/print/4 - Experimental/minion/Widow_print.webp",
    "icon": "./images/icon/4 - Experimental/minion/Widow_icon.webp",
    "version": "4b - Unreleased Experimental",
    "isDisabled": false
  },
  {
    "id": "Godfather",
    "name": "Godfather",
    "roleType": "minion",
    "print": "./images/print/2 - Bad Moon Rising/minion/Godfather_print.webp",
    "icon": "./images/icon/2 - Bad Moon Rising/minion/Godfather_icon.webp",
    "version": "2 - Bad Moon Rising",
    "isDisabled": false
  },
  {
    "id": "Poisoner",
    "name": "Poisoner",
    "roleType": "minion",
    "print": "./images/print/1 - Trouble Brewing/minion/Poisoner_print.webp",
    "icon": "./images/icon/1 - Trouble Brewing/minion/Poisoner_icon.webp",
    "version": "1 - Trouble Brewing",
    "isDisabled": false
  },
  {
    "id": "Devil's Advocate",
    "name": "Devil's Advocate",
    "roleType": "minion",
    "print": "./images/print/2 - Bad Moon Rising/minion/Devil's Advocate_print.webp",
    "icon": "./images/icon/2 - Bad Moon Rising/minion/Devil's Advocate_icon.webp",
    "version": "2 - Bad Moon Rising",
    "isDisabled": false
  },
  {
    "id": "Harpy",
    "name": "Harpy",
    "roleType": "minion",
    "print": "./images/print/4 - Experimental/minion/Harpy_print.webp",
    "icon": "./images/icon/4 - Experimental/minion/Harpy_icon.webp",
    "version": "4b - Unreleased Experimental",
    "isDisabled": false
  },
  {
    "id": "Spy",
    "name": "Spy",
    "roleType": "minion",
    "print": "./images/print/1 - Trouble Brewing/minion/Spy_print.webp",
    "icon": "./images/icon/1 - Trouble Brewing/minion/Spy_icon.webp",
    "version": "1 - Trouble Brewing",
    "isDisabled": false
  },
  {
    "id": "Summoner",
    "name": "Summoner",
    "roleType": "minion",
    "print": "./images/print/4 - Experimental/minion/Summoner_print.webp",
    "icon": "./images/icon/4 - Experimental/minion/Summoner_icon.webp",
    "version": "4b - Unreleased Experimental",
    "isDisabled": false
  },
  {
    "id": "Witch",
    "name": "Witch",
    "roleType": "minion",
    "print": "./images/print/3 - Sects and Violets/minion/Witch_print.webp",
    "icon": "./images/icon/3 - Sects and Violets/minion/Witch_icon.webp",
    "version": "3 - Sects and Violets",
    "isDisabled": false
  },
  {
    "id": "Cerenovus",
    "name": "Cerenovus",
    "roleType": "minion",
    "print": "./images/print/3 - Sects and Violets/minion/Cerenovus_print.webp",
    "icon": "./images/icon/3 - Sects and Violets/minion/Cerenovus_icon.webp",
    "version": "3 - Sects and Violets",
    "isDisabled": false
  },
  {
    "id": "Fearmonger",
    "name": "Fearmonger",
    "roleType": "minion",
    "print": "./images/print/4 - Experimental/minion/Fearmonger_print.webp",
    "icon": "./images/icon/4 - Experimental/minion/Fearmonger_icon.webp",
    "version": "4a - Kickstarter Experimental",
    "isDisabled": false
  },
  {
    "id": "Pit-Hag",
    "name": "Pit-Hag",
    "roleType": "minion",
    "print": "./images/print/3 - Sects and Violets/minion/Pit-Hag_print.webp",
    "icon": "./images/icon/3 - Sects and Violets/minion/Pit-Hag_icon.webp",
    "version": "3 - Sects and Violets",
    "isDisabled": false
  },
  {
    "id": "Psychopath",
    "name": "Psychopath",
    "roleType": "minion",
    "print": "./images/print/4 - Experimental/minion/Psychopath_print.webp",
    "icon": "./images/icon/4 - Experimental/minion/Psychopath_icon.webp",
    "version": "4a - Kickstarter Experimental",
    "isDisabled": false
  },
  {
    "id": "Assassin",
    "name": "Assassin",
    "roleType": "minion",
    "print": "./images/print/2 - Bad Moon Rising/minion/Assassin_print.webp",
    "icon": "./images/icon/2 - Bad Moon Rising/minion/Assassin_icon.webp",
    "version": "2 - Bad Moon Rising",
    "isDisabled": false
  },
  {
    "id": "Baron",
    "name": "Baron",
    "roleType": "minion",
    "print": "./images/print/1 - Trouble Brewing/minion/Baron_print.webp",
    "icon": "./images/icon/1 - Trouble Brewing/minion/Baron_icon.webp",
    "version": "1 - Trouble Brewing",
    "isDisabled": false
  },
  {
    "id": "Mezepheles",
    "name": "Mezepheles",
    "roleType": "minion",
    "print": "./images/print/4 - Experimental/minion/Mezepheles_print.webp",
    "icon": "./images/icon/4 - Experimental/minion/Mezepheles_icon.webp",
    "version": "4a - Kickstarter Experimental",
    "isDisabled": false
  },
  {
    "id": "Goblin",
    "name": "Goblin",
    "roleType": "minion",
    "print": "./images/print/4 - Experimental/minion/Goblin_print.webp",
    "icon": "./images/icon/4 - Experimental/minion/Goblin_icon.webp",
    "version": "4b - Unreleased Experimental",
    "isDisabled": false
  },
  {
    "id": "Scarlet Woman",
    "name": "Scarlet Woman",
    "roleType": "minion",
    "print": "./images/print/1 - Trouble Brewing/minion/Scarlet Woman_print.webp",
    "icon": "./images/icon/1 - Trouble Brewing/minion/Scarlet Woman_icon.webp",
    "version": "1 - Trouble Brewing",
    "isDisabled": false
  },
  {
    "id": "Evil Twin",
    "name": "Evil Twin",
    "roleType": "minion",
    "print": "./images/print/3 - Sects and Violets/minion/Evil Twin_print.webp",
    "icon": "./images/icon/3 - Sects and Violets/minion/Evil Twin_icon.webp",
    "version": "3 - Sects and Violets",
    "isDisabled": false
  },
  {
    "id": "Organ Grinder",
    "name": "Organ Grinder",
    "roleType": "minion",
    "print": "./images/print/4 - Experimental/minion/Organ Grinder_print.webp",
    "icon": "./images/icon/4 - Experimental/minion/Organ Grinder_icon.webp",
    "version": "4b - Unreleased Experimental",
    "isDisabled": false
  },
  {
    "id": "Vizier",
    "name": "Vizier",
    "roleType": "minion",
    "print": "./images/print/4 - Experimental/minion/Vizier_print.webp",
    "icon": "./images/icon/4 - Experimental/minion/Vizier_icon.webp",
    "version": "4b - Unreleased Experimental",
    "isDisabled": false
  },
  {
    "id": "Mastermind",
    "name": "Mastermind",
    "roleType": "minion",
    "print": "./images/print/2 - Bad Moon Rising/minion/Mastermind_print.webp",
    "icon": "./images/icon/2 - Bad Moon Rising/minion/Mastermind_icon.webp",
    "version": "2 - Bad Moon Rising",
    "isDisabled": false
  },
  {
    "id": "Boomdandy",
    "name": "Boomdandy",
    "roleType": "minion",
    "print": "./images/print/4 - Experimental/minion/Boomdandy_print.webp",
    "icon": "./images/icon/4 - Experimental/minion/Boomdandy_icon.webp",
    "version": "4a - Kickstarter Experimental",
    "isDisabled": false
  },
  {
    "id": "Marionette",
    "name": "Marionette",
    "roleType": "minion",
    "print": "./images/print/4 - Experimental/minion/Marionette_print.webp",
    "icon": "./images/icon/4 - Experimental/minion/Marionette_icon.webp",
    "version": "4a - Kickstarter Experimental",
    "isDisabled": false
  },
  {
    "id": "Yaggababble",
    "name": "Yaggababble",
    "roleType": "demon",
    "print": "./images/print/4 - Experimental/demon/Yaggababble_print.webp",
    "icon": "./images/icon/4 - Experimental/demon/Yaggababble_icon.webp",
    "version": "4b - Unreleased Experimental",
    "isDisabled": false
  },
  {
    "id": "Kazali",
    "name": "Kazali",
    "roleType": "demon",
    "print": "./images/print/4 - Experimental/demon/Kazali_print.webp",
    "icon": "./images/icon/4 - Experimental/demon/Kazali_icon.webp",
    "version": "4b - Unreleased Experimental",
    "isDisabled": false
  },
  {
    "id": "Pukka",
    "name": "Pukka",
    "roleType": "demon",
    "print": "./images/print/2 - Bad Moon Rising/demon/Pukka_print.webp",
    "icon": "./images/icon/2 - Bad Moon Rising/demon/Pukka_icon.webp",
    "version": "2 - Bad Moon Rising",
    "isDisabled": false
  },
  {
    "id": "Lil' Monsta",
    "name": "Lil' Monsta",
    "roleType": "demon",
    "print": "./images/print/4 - Experimental/demon/Lil' Monsta_print.webp",
    "icon": "./images/icon/4 - Experimental/demon/Lil' Monsta_icon.webp",
    "version": "4b - Unreleased Experimental",
    "isDisabled": false
  },
  {
    "id": "Lleech",
    "name": "Lleech",
    "roleType": "demon",
    "print": "./images/print/4 - Experimental/demon/Lleech_print.webp",
    "icon": "./images/icon/4 - Experimental/demon/Lleech_icon.webp",
    "version": "4a - Kickstarter Experimental",
    "isDisabled": false
  },
  {
    "id": "No Dashii",
    "name": "No Dashii",
    "roleType": "demon",
    "print": "./images/print/3 - Sects and Violets/demon/No Dashii_print.webp",
    "icon": "./images/icon/3 - Sects and Violets/demon/No Dashii_icon.webp",
    "version": "3 - Sects and Violets",
    "isDisabled": false
  },
  {
    "id": "Imp",
    "name": "Imp",
    "roleType": "demon",
    "print": "./images/print/1 - Trouble Brewing/demon/Imp_print.webp",
    "icon": "./images/icon/1 - Trouble Brewing/demon/Imp_icon.webp",
    "version": "1 - Trouble Brewing",
    "isDisabled": false
  },
  {
    "id": "Ojo",
    "name": "Ojo",
    "roleType": "demon",
    "print": "./images/print/4 - Experimental/demon/Ojo_print.webp",
    "icon": "./images/icon/4 - Experimental/demon/Ojo_icon.webp",
    "version": "4b - Unreleased Experimental",
    "isDisabled": false
  },
  {
    "id": "Shabaloth",
    "name": "Shabaloth",
    "roleType": "demon",
    "print": "./images/print/2 - Bad Moon Rising/demon/Shabaloth_print.webp",
    "icon": "./images/icon/2 - Bad Moon Rising/demon/Shabaloth_icon.webp",
    "version": "2 - Bad Moon Rising",
    "isDisabled": false
  },
  {
    "id": "Po",
    "name": "Po",
    "roleType": "demon",
    "print": "./images/print/2 - Bad Moon Rising/demon/Po_print.webp",
    "icon": "./images/icon/2 - Bad Moon Rising/demon/Po_icon.webp",
    "version": "2 - Bad Moon Rising",
    "isDisabled": false
  },
  {
    "id": "Vortox",
    "name": "Vortox",
    "roleType": "demon",
    "print": "./images/print/3 - Sects and Violets/demon/Vortox_print.webp",
    "icon": "./images/icon/3 - Sects and Violets/demon/Vortox_icon.webp",
    "version": "3 - Sects and Violets",
    "isDisabled": false
  },
  {
    "id": "Vigormortis",
    "name": "Vigormortis",
    "roleType": "demon",
    "print": "./images/print/3 - Sects and Violets/demon/Vigormortis_print.webp",
    "icon": "./images/icon/3 - Sects and Violets/demon/Vigormortis_icon.webp",
    "version": "3 - Sects and Violets",
    "isDisabled": false
  },
  {
    "id": "Legion",
    "name": "Legion",
    "roleType": "demon",
    "print": "./images/print/4 - Experimental/demon/Legion_print.webp",
    "icon": "./images/icon/4 - Experimental/demon/Legion_icon.webp",
    "version": "4a - Kickstarter Experimental",
    "isDisabled": false
  },
  {
    "id": "Al-Hadikhia",
    "name": "Al-Hadikhia",
    "roleType": "demon",
    "print": "./images/print/4 - Experimental/demon/Al-Hadikhia_print.webp",
    "icon": "./images/icon/4 - Experimental/demon/Al-Hadikhia_icon.webp",
    "version": "4a - Kickstarter Experimental",
    "isDisabled": false
  },
  {
    "id": "Zombuul",
    "name": "Zombuul",
    "roleType": "demon",
    "print": "./images/print/2 - Bad Moon Rising/demon/Zombuul_print.webp",
    "icon": "./images/icon/2 - Bad Moon Rising/demon/Zombuul_icon.webp",
    "version": "2 - Bad Moon Rising",
    "isDisabled": false
  },
  {
    "id": "Fang Gu",
    "name": "Fang Gu",
    "roleType": "demon",
    "print": "./images/print/3 - Sects and Violets/demon/Fang Gu_print.webp",
    "icon": "./images/icon/3 - Sects and Violets/demon/Fang Gu_icon.webp",
    "version": "3 - Sects and Violets",
    "isDisabled": false
  },
  {
    "id": "Leviathan",
    "name": "Leviathan",
    "roleType": "demon",
    "print": "./images/print/4 - Experimental/demon/Leviathan_print.webp",
    "icon": "./images/icon/4 - Experimental/demon/Leviathan_icon.webp",
    "version": "4a - Kickstarter Experimental",
    "isDisabled": false
  },
  {
    "id": "Riot",
    "name": "Riot",
    "roleType": "demon",
    "print": "./images/print/4 - Experimental/demon/Riot_print.webp",
    "icon": "./images/icon/4 - Experimental/demon/Riot_icon.webp",
    "version": "4a - Kickstarter Experimental",
    "isDisabled": false
  },
  {
    "id": "Angel",
    "name": "Angel",
    "roleType": "fabled",
    "print": "./images/print/Extras/fabled/Angel_print.webp",
    "icon": "./images/icon/Extras/fabled/Angel_icon.webp",
    "version": "Extras",
    "isDisabled": false
  },
  {
    "id": "Bootlegger",
    "name": "Bootlegger",
    "roleType": "fabled",
    "print": "./images/print/Extras/fabled/Bootlegger_print.webp",
    "icon": "./images/icon/Extras/fabled/Bootlegger_icon.webp",
    "version": "Extras",
    "isDisabled": false
  },
  {
    "id": "Buddhist",
    "name": "Buddhist",
    "roleType": "fabled",
    "print": "./images/print/Extras/fabled/Buddhist_print.webp",
    "icon": "./images/icon/Extras/fabled/Buddhist_icon.webp",
    "version": "Extras",
    "isDisabled": false
  },
  {
    "id": "Djinn",
    "name": "Djinn",
    "roleType": "fabled",
    "print": "./images/print/Extras/fabled/Djinn_print.webp",
    "icon": "./images/icon/Extras/fabled/Djinn_icon.webp",
    "version": "Extras",
    "isDisabled": false
  },
  {
    "id": "Doomsayer",
    "name": "Doomsayer",
    "roleType": "fabled",
    "print": "./images/print/Extras/fabled/Doomsayer_print.webp",
    "icon": "./images/icon/Extras/fabled/Doomsayer_icon.webp",
    "version": "Extras",
    "isDisabled": false
  },
  {
    "id": "Duchess",
    "name": "Duchess",
    "roleType": "fabled",
    "print": "./images/print/Extras/fabled/Duchess_print.webp",
    "icon": "./images/icon/Extras/fabled/Duchess_icon.webp",
    "version": "Extras",
    "isDisabled": false
  },
  {
    "id": "Ferryman",
    "name": "Ferryman",
    "roleType": "fabled",
    "print": "./images/print/Extras/fabled/Ferryman_print.webp",
    "icon": "./images/icon/Extras/fabled/Ferryman_icon.webp",
    "version": "Extras",
    "isDisabled": false
  },
  {
    "id": "Fibbin",
    "name": "Fibbin",
    "roleType": "fabled",
    "print": "./images/print/Extras/fabled/Fibbin_print.webp",
    "icon": "./images/icon/Extras/fabled/Fibbin_icon.webp",
    "version": "Extras",
    "isDisabled": false
  },
  {
    "id": "Fiddler",
    "name": "Fiddler",
    "roleType": "fabled",
    "print": "./images/print/Extras/fabled/Fiddler_print.webp",
    "icon": "./images/icon/Extras/fabled/Fiddler_icon.webp",
    "version": "Extras",
    "isDisabled": false
  },
  {
    "id": "Gardener",
    "name": "Gardener",
    "roleType": "fabled",
    "print": "./images/print/Extras/fabled/Gardener_print.webp",
    "icon": "./images/icon/Extras/fabled/Gardener_icon.webp",
    "version": "Extras",
    "isDisabled": false
  },
  {
    "id": "Hell's Librarian",
    "name": "Hell's Librarian",
    "roleType": "fabled",
    "print": "./images/print/Extras/fabled/Hell's Librarian_print.webp",
    "icon": "./images/icon/Extras/fabled/Hell's Librarian_icon.webp",
    "version": "Extras",
    "isDisabled": false
  },
  {
    "id": "Revolutionary",
    "name": "Revolutionary",
    "roleType": "fabled",
    "print": "./images/print/Extras/fabled/Revolutionary_print.webp",
    "icon": "./images/icon/Extras/fabled/Revolutionary_icon.webp",
    "version": "Extras",
    "isDisabled": false
  },
  {
    "id": "Sentinel",
    "name": "Sentinel",
    "roleType": "fabled",
    "print": "./images/print/Extras/fabled/Sentinel_print.webp",
    "icon": "./images/icon/Extras/fabled/Sentinel_icon.webp",
    "version": "Extras",
    "isDisabled": false
  },
  {
    "id": "Spirit Of Ivory",
    "name": "Spirit Of Ivory",
    "roleType": "fabled",
    "print": "./images/print/Extras/fabled/Spirit Of Ivory_print.webp",
    "icon": "./images/icon/Extras/fabled/Spirit Of Ivory_icon.webp",
    "version": "Extras",
    "isDisabled": false
  },
  {
    "id": "Storm Catcher",
    "name": "Storm Catcher",
    "roleType": "fabled",
    "print": "./images/print/Extras/fabled/Storm Catcher_print.webp",
    "icon": "./images/icon/Extras/fabled/Storm Catcher_icon.webp",
    "version": "4a - Kickstarter Experimental",
    "isDisabled": false
  },
  {
    "id": "Toymaker",
    "name": "Toymaker",
    "roleType": "fabled",
    "print": "./images/print/Extras/fabled/Toymaker_print.webp",
    "icon": "./images/icon/Extras/fabled/Toymaker_icon.webp",
    "version": "Extras",
    "isDisabled": false
  },
  {
    "id": "Apprentice",
    "name": "Apprentice",
    "roleType": "travellers",
    "print": "./images/print/Extras/travellers/Apprentice_print.webp",
    "icon": "./images/icon/Extras/travellers/Apprentice_icon.webp",
    "version": "2 - Bad Moon Rising",
    "isDisabled": false
  },
  {
    "id": "Barista",
    "name": "Barista",
    "roleType": "travellers",
    "print": "./images/print/Extras/travellers/Barista_print.webp",
    "icon": "./images/icon/Extras/travellers/Barista_icon.webp",
    "version": "3 - Sects and Violets",
    "isDisabled": false
  },
  {
    "id": "Beggar",
    "name": "Beggar",
    "roleType": "travellers",
    "print": "./images/print/Extras/travellers/Beggar_print.webp",
    "icon": "./images/icon/Extras/travellers/Beggar_icon.webp",
    "version": "1 - Trouble Brewing",
    "isDisabled": false
  },
  {
    "id": "Bishop",
    "name": "Bishop",
    "roleType": "travellers",
    "print": "./images/print/Extras/travellers/Bishop_print.webp",
    "icon": "./images/icon/Extras/travellers/Bishop_icon.webp",
    "version": "2 - Bad Moon Rising",
    "isDisabled": false
  },
  {
    "id": "Bone Collector",
    "name": "Bone Collector",
    "roleType": "travellers",
    "print": "./images/print/Extras/travellers/Bone Collector_print.webp",
    "icon": "./images/icon/Extras/travellers/Bone Collector_icon.webp",
    "version": "3 - Sects and Violets",
    "isDisabled": false
  },
  {
    "id": "Bureaucrat",
    "name": "Bureaucrat",
    "roleType": "travellers",
    "print": "./images/print/Extras/travellers/Bureaucrat_print.webp",
    "icon": "./images/icon/Extras/travellers/Bureaucrat_icon.webp",
    "version": "1 - Trouble Brewing",
    "isDisabled": false
  },
  {
    "id": "Butcher",
    "name": "Butcher",
    "roleType": "travellers",
    "print": "./images/print/Extras/travellers/Butcher_print.webp",
    "icon": "./images/icon/Extras/travellers/Butcher_icon.webp",
    "version": "3 - Sects and Violets",
    "isDisabled": false
  },
  {
    "id": "Deviant",
    "name": "Deviant",
    "roleType": "travellers",
    "print": "./images/print/Extras/travellers/Deviant_print.webp",
    "icon": "./images/icon/Extras/travellers/Deviant_icon.webp",
    "version": "3 - Sects and Violets",
    "isDisabled": false
  },
  {
    "id": "Gangster",
    "name": "Gangster",
    "roleType": "travellers",
    "print": "./images/print/Extras/travellers/Gangster_print.webp",
    "icon": "./images/icon/Extras/travellers/Gangster_icon.webp",
    "version": "4a - Kickstarter Experimental",
    "isDisabled": false
  },
  {
    "id": "Gunslinger",
    "name": "Gunslinger",
    "roleType": "travellers",
    "print": "./images/print/Extras/travellers/Gunslinger_print.webp",
    "icon": "./images/icon/Extras/travellers/Gunslinger_icon.webp",
    "version": "1 - Trouble Brewing",
    "isDisabled": false
  },
  {
    "id": "Harlot",
    "name": "Harlot",
    "roleType": "travellers",
    "print": "./images/print/Extras/travellers/Harlot_print.webp",
    "icon": "./images/icon/Extras/travellers/Harlot_icon.webp",
    "version": "3 - Sects and Violets",
    "isDisabled": false
  },
  {
    "id": "Judge",
    "name": "Judge",
    "roleType": "travellers",
    "print": "./images/print/Extras/travellers/Judge_print.webp",
    "icon": "./images/icon/Extras/travellers/Judge_icon.webp",
    "version": "2 - Bad Moon Rising",
    "isDisabled": false
  },
  {
    "id": "Matron",
    "name": "Matron",
    "roleType": "travellers",
    "print": "./images/print/Extras/travellers/Matron_print.webp",
    "icon": "./images/icon/Extras/travellers/Matron_icon.webp",
    "version": "2 - Bad Moon Rising",
    "isDisabled": false
  },
  {
    "id": "Scapegoat",
    "name": "Scapegoat",
    "roleType": "travellers",
    "print": "./images/print/Extras/travellers/Scapegoat_print.webp",
    "icon": "./images/icon/Extras/travellers/Scapegoat_icon.webp",
    "version": "1 - Trouble Brewing",
    "isDisabled": false
  },
  {
    "id": "Thief",
    "name": "Thief",
    "roleType": "travellers",
    "print": "./images/print/Extras/travellers/Thief_print.webp",
    "icon": "./images/icon/Extras/travellers/Thief_icon.webp",
    "version": "1 - Trouble Brewing",
    "isDisabled": false
  },
  {
    "id": "Voudon",
    "name": "Voudon",
    "roleType": "travellers",
    "print": "./images/print/Extras/travellers/Voudon_print.webp",
    "icon": "./images/icon/Extras/travellers/Voudon_icon.webp",
    "version": "2 - Bad Moon Rising",
    "isDisabled": false
  }
]
